import { Button } from '@krakentech/coral';
import { FC } from 'react';

import { ConstantineShape } from '@/components/svgs/ConstantineShape';
import {
	GA_EVENT_HOMEPAGE_PRICECHECK_HOUSEHOLD_1,
	GA_EVENT_HOMEPAGE_PRICECHECK_HOUSEHOLD_2_TO_3,
	GA_EVENT_HOMEPAGE_PRICECHECK_HOUSEHOLD_4_PLUS,
} from '@/utils/constants/analytics';
import { ColorHex } from '@/utils/constants/colors';
import { UsageTier } from '@/utils/constants/industry/industry';
import { sendHomepagePriceCheckHouseholdAnalytics } from '@/utils/googleAnalytics';

export type UsageTierButtonProps = {
	onClick?: (usageTier: UsageTier) => void;
	usageTier: UsageTier;
	value?: UsageTier;
};

export const UsageTierButton: FC<UsageTierButtonProps> = ({
	usageTier,
	onClick,
	value,
}) => {
	const usageTiers: Record<UsageTier, [string, number, string]> = {
		LOW: ['1人', 1, GA_EVENT_HOMEPAGE_PRICECHECK_HOUSEHOLD_1],
		MEDIUM: ['2~3人', 2, GA_EVENT_HOMEPAGE_PRICECHECK_HOUSEHOLD_2_TO_3],
		HIGH: ['4人以上', 4, GA_EVENT_HOMEPAGE_PRICECHECK_HOUSEHOLD_4_PLUS],
	};

	const [text, iconCount, gaEvent] = usageTiers[usageTier];

	const handleClick = () => {
		sendHomepagePriceCheckHouseholdAnalytics(gaEvent);
		onClick?.(usageTier);
	};

	return (
		<Button
			variant={value === usageTier ? 'contained' : 'outlined'}
			color="secondary"
			size="small"
			onClick={handleClick}
		>
			<div className="flex w-full min-w-max items-center justify-center space-x-4 text-sm md:justify-between md:text-base">
				<div className="flex space-x-2 py-1">
					{Array.from(Array(iconCount), (index) => (
						<ConstantineShape
							key={index}
							fill={ColorHex.soholights}
							height={20}
							width={18}
						/>
					))}
				</div>
				<span className="text-sm font-semibold">{text}</span>
			</div>
		</Button>
	);
};
