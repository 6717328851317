import { Button, Typography } from '@krakentech/coral';
import * as Sentry from '@sentry/nextjs';
import { useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';
import React, { FC, ReactNode } from 'react';

import { Layout } from '@/components/layouts/landingPage/Layout';
import { WellSection } from '@/components/layouts/landingPage/WellSection';
import { HomePageSectionBlog } from '@/components/pages/home/HomePageSectionBlog';
import { HomePageSectionHeading } from '@/components/pages/home/HomePageSectionHeading';
import { HomePageSectionHero } from '@/components/pages/home/HomePageSectionHero';
import { HomepageSectionKantan } from '@/components/pages/home/HomePageSectionKantan';
import { HomepageSectionMedia } from '@/components/pages/home/HomePageSectionMedia';
import { HomePageSectionProducts } from '@/components/pages/home/HomePageSectionProducts';
import { HomePageSectionQuote } from '@/components/pages/home/HomePageSectionQuote';
import { HomePageSectionSellingPoints } from '@/components/pages/home/HomePageSectionSellingPoints';
import { ConstantineEnergyAlert } from '@/components/shared/ConstantineEnergyAlert';
import { ErrorOverlay } from '@/components/shared/ErrorOverlay';
import { GoogleReviews } from '@/components/shared/GoogleReviews';
import { CustomBannerHandler } from '@/components/storyblok/bloks/banner-components/CustomBannerHandler';
import { RewardBannerHandler } from '@/components/storyblok/bloks/banner-components/RewardBannerHandler';
import { StoryblokBannerType } from '@/components/storyblok/utils/constants';
import { copy } from '@/copy';
import { featureFlags } from '@/features/featureFlags';
import { BlogpostItemFragment } from '@/services/graphql-storyblok';
import { OCTOPUS_HOUSEHOLDS_ESTIMATE } from '@/utils/constants/marketing';
import { sendHomepageGoogleReviewMoreButtonAnalytics } from '@/utils/googleAnalytics';

export type Props = {
	featuredBlogStories: BlogpostItemFragment[];
};

const DynamicPVserviceBanner = dynamic(
	async () =>
		(await import('@/components/pages/campaigns/pv-service/PVServiceBanner'))
			.PvServiceBanner,
	{ ssr: false }
);

export const HomePage: FC<Props> = ({ featuredBlogStories }) => {
	const { t } = useTranslation();

	const withErrorBoundary = (children: ReactNode) => (
		<Sentry.ErrorBoundary
			fallback={({ resetError }) => (
				<ErrorOverlay
					message={t('common:errors.general-error')}
					buttonText={copy.tryAgain}
					onClick={resetError}
				/>
			)}
		>
			{children}
		</Sentry.ErrorBoundary>
	);

	return (
		<Layout className="mx-auto flex min-h-screen w-full flex-col">
			<main>
				<CustomBannerHandler
					slug={StoryblokBannerType.primary}
					page="landing"
				/>
				<CustomBannerHandler
					slug={StoryblokBannerType.secondary}
					page="landing"
					variant="tertiary"
				/>
				<RewardBannerHandler />

				<>
					<WellSection
						backgroundColor="bg-hemocyanin"
						padding="px-4 sm:px-8 pt-6 pb-12 md:pb-16"
					>
						<ConstantineEnergyAlert />
						<HomePageSectionHero />
					</WellSection>
					<WellSection backgroundColor="bg-siphon" padding="px-4 sm:px-8 py-12">
						<HomePageSectionSellingPoints />
					</WellSection>
					{withErrorBoundary(
						<WellSection
							backgroundColor="bg-hemocyanin"
							padding="px-0 py-8 sm:px-8 md:py-12"
						>
							<HomePageSectionQuote />
						</WellSection>
					)}
				</>

				<WellSection
					backgroundColor="bg-hemocyanin"
					padding="px-4 sm:px-8 py-12 md:py-24"
				>
					{/* Customer feedback. */}
					{/* An example of a Google review */}
					<HomePageSectionHeading
						headingText="Googleレビューの一例"
						subheadingText="お客さまから寄せられた声"
					/>
					<GoogleReviews />
					<div className="mt-8">
						<Button
							fullWidth
							color="tertiary"
							variant="text"
							target="_blank"
							onClick={sendHomepageGoogleReviewMoreButtonAnalytics}
							href="https://www.google.com/search?q=%E3%82%AA%E3%82%AF%E3%83%88%E3%83%91%E3%82%B9%E3%82%A8%E3%83%8A%E3%82%B8%E3%83%BC&sca_esv=596692341&rlz=1C5CHFA_enJP1002JP1002&sxsrf=AM9HkKm7ZpyG9ZDBfvUO4DQXtJI2p0oW8A%3A1704757867274&ei=a4qcZd-YENOv0-kPxdmKsAg&ved=0ahUKEwif-uz4_c6DAxXT1zQHHcWsAoYQ4dUDCBA&uact=5&oq=%E3%82%AA%E3%82%AF%E3%83%88%E3%83%91%E3%82%B9%E3%82%A8%E3%83%8A%E3%82%B8%E3%83%BC&gs_lp=Egxnd3Mtd2l6LXNlcnAiG-OCquOCr-ODiOODkeOCueOCqOODiuOCuOODvDIKEAAYRxjWBBiwAzIKEAAYRxjWBBiwAzIKEAAYRxjWBBiwAzIKEAAYRxjWBBiwAzIKEAAYRxjWBBiwAzIKEAAYRxjWBBiwAzIKEAAYRxjWBBiwAzIKEAAYRxjWBBiwAzIKEAAYRxjWBBiwAzIKEAAYRxjWBBiwA0jjTVCgB1i9SnAJeAGQAQOYAZACoAG1KaoBBjEuMzAuNLgBA8gBAPgBAagCFMICBxAjGOoCGCfCAhQQABiABBjjBBjpBBjqAhi0AtgBAcICDxAjGIAEGIoFGCcYRhj_AcICBBAjGCfCAgYQABgDGATCAgoQABiABBgEGLEDwgINEAAYgAQYBBixAxiDAcICIRAAGIAEGIoFGEYY_wEYlwUYjAUY3QQYRhj0Axj1A9gBAsICChAjGIAEGIoFGCfCAgsQABiABBixAxiDAcICChAAGIAEGIoFGEPCAgcQABiABBgEwgIQEAAYgAQYigUYQxixAxiDAcICBRAAGIAEwgINEAAYgAQYigUYQxixA8ICBhAAGAQYHsICCBAAGIAEGKIEwgIIEAAYiQUYogTCAgkQABiABBgEGArCAggQABiABBixA-IDBBgAIEGIBgGQBgq6BgYIARABGAG6BgYIAhABGBM&sclient=gws-wiz-serp"
						>
							Googleレビューをもっと見る
						</Button>
					</div>
				</WellSection>
				<WellSection
					backgroundColor="bg-siphon"
					padding="px-4 sm:px-8 py-12 md:py-24"
				>
					<div className="flex flex-col gap-2">
						<Typography variant="h3" textAlign="center">
							{/*	Join the OCTOPUS_HOUSEHOLDS_ESTIMATE households around the world who love Octopus' 5 ⭐️ services!*/}
							世界でオクトパスの５つ⭐️サービスを愛する
							<span className="text-voltage">
								{OCTOPUS_HOUSEHOLDS_ESTIMATE}万件
							</span>
							に仲間入り！
						</Typography>
					</div>
				</WellSection>
				<WellSection
					backgroundColor="bg-hemocyanin"
					padding="px-4 sm:px-8 py-12 md:py-24"
				>
					<HomePageSectionProducts />
					{featureFlags.NEXT_PUBLIC_PV_SERVICE ? (
						<DynamicPVserviceBanner />
					) : null}
					<HomepageSectionKantan />
				</WellSection>
				<WellSection
					backgroundColor="bg-siphon"
					padding="px-4 sm:px-8 py-12 md:py-24"
				>
					<HomepageSectionMedia />
				</WellSection>
				<WellSection
					backgroundColor="bg-hemocyanin"
					padding="px-4 sm:px-8 py-12 md:py-24"
				>
					{featuredBlogStories && (
						<HomePageSectionBlog featuredBlogStories={featuredBlogStories} />
					)}
				</WellSection>
			</main>
		</Layout>
	);
};
