import { Card } from '@krakentech/coral';
import { FC } from 'react';

import { UsageTierButton } from '@/components/pages/home/Shortcut/UsageTierButton';
import { House } from '@/components/svgs/House';
import { UsageTier } from '@/utils/constants/industry/industry';

export type UsageTierButtonProps = {
	onSelect: (usageTier: UsageTier) => void;
	value?: UsageTier;
};

export const UsageTierSelect: FC<UsageTierButtonProps> = ({
	onSelect,
	value,
}) => (
	<Card theme="dark" borderless padding="none">
		<div className="py-4 px-5 text-white md:py-8 md:px-14">
			<div className="mb-4 flex items-center justify-center gap-2 md:gap-4">
				<div className="w-8">
					<House />
				</div>
				<div className="text-lg md:text-2xl">何人でお住まいですか？</div>
			</div>
			<div className="grid grid-rows-3 gap-4 md:gap-5">
				<UsageTierButton onClick={onSelect} value={value} usageTier="LOW" />
				<UsageTierButton onClick={onSelect} value={value} usageTier="MEDIUM" />
				<UsageTierButton onClick={onSelect} value={value} usageTier="HIGH" />
			</div>
		</div>
	</Card>
);
