import { Card } from '@krakentech/coral';
import Image from 'next/image';
import { FC, ReactNode } from 'react';

import {
	SupportedArea,
	SUPPORTED_AREAS,
} from '@/utils/constants/industry/gridOperator';
import { logMessage } from '@/utils/logger';

const MapCardDataByArea: Record<
	SupportedArea,
	{ content: ReactNode; extra?: ReactNode; src: string; title: ReactNode }
> = {
	// 01 - Hokkaido
	北海道: {
		title: '対象となるお客さま',
		content: '北海道にお住まいのお客さま',
		extra: '※離島は除く',
		src: '/images/maps/maps-01.png',
	},
	// 02 - Touhoku
	東北: {
		title: '対象となるお客さま',
		content:
			'青森県、岩手県、秋田県、宮城県、山形県、福島県、新潟県にお住まいのお客さま',
		extra: '※離島は除く',
		src: '/images/maps/maps-02.png',
	},
	// 03 - Kanto
	関東: {
		title: '対象となるお客さま',
		content:
			'茨城県、栃木県、群馬県、埼玉県、千葉県、東京都、神奈川県、山梨県、静岡県の一部（富士川以東）にお住いのお客さま',
		extra: '※離島は除く',
		src: '/images/maps/maps-03.png',
	},
	// 04 - Chubu
	中部: {
		title: '対象となるお客さま',
		content:
			'愛知県、岐阜県（不破郡関ケ原町の一部を除く）、三重県（熊野市、南牟婁郡紀宝町、南牟婁郡御浜町を除く）、静岡県の一部（富士川以西）、長野県にお住まいのお客さま',
		extra: '※離島は除く',
		src: '/images/maps/maps-04.png',
	},
	// 05 - Hokuriku
	北陸: {
		title: '対象となるお客さま',
		content:
			'富山県、石川県、福井県（一部を除く）、岐阜県の一部にお住まいのお客さま',
		extra: '※離島は除く',
		src: '/images/maps/maps-05.png',
	},
	// 06 - Kansai
	関西: {
		title: '対象となるお客さま',
		content:
			'京都府、大阪府、滋賀県、兵庫県（赤穂市福浦を除く）、奈良県、和歌山県、福井県（三方郡美浜町以西）、三重県（熊野市、南牟婁郡紀宝町、南牟婁郡御浜町）、岐阜県（不破郡関ケ原町の一部）にお住まいのお客さま',
		src: '/images/maps/maps-06.png',
	},
	// 07 - Chugoku
	中国: {
		title: '対象となるお客さま',
		content:
			'鳥取県、島根県、岡山県、広島県、山口県、兵庫県の一部、香川県の一部、愛媛県の一部にお住まいのお客さま',
		extra: '※離島は除く',
		src: '/images/maps/maps-07.png',
	},
	// 08 - Shikoku
	四国: {
		title: '対象となるお客さま',
		content:
			'徳島県、高知県、香川県（一部を除く）、愛媛県（一部を除く）にお住まいのお客さま',
		src: '/images/maps/maps-08.png',
	},
	// 09 - Kyushu
	九州: {
		title: '対象となるお客さま',
		content:
			'福岡県、佐賀県、長崎県、熊本県、大分県、宮崎県、鹿児島県にお住まいのお客さま',
		extra: '※離島は除く',
		src: '/images/maps/maps-09.png',
	},
};

export const GridAreaMapCard: FC<{ selectedArea: SupportedArea }> = ({
	selectedArea,
}) => {
	// It's possible for an unsupported area to be selected at runtime. Ensure we have a valid area before rendering card.
	if (!selectedArea || !SUPPORTED_AREAS.includes(selectedArea)) {
		logMessage('Unsupported area selected', { selectedArea }, 'info');
		return null;
	}

	const { src, title, content, extra } = MapCardDataByArea[selectedArea];

	return (
		<Card theme="mid">
			<div className="flex flex-col items-center justify-between gap-4 md:flex-row">
				<div>
					<h3 className="text-lg font-bold md:text-xl">{title}</h3>
					<p className="mt-4 text-sm md:text-base">{content}</p>
					{extra && <p className="mt-8 text-sm md:text-base">{extra}</p>}
				</div>
				<Image
					src={src}
					alt={selectedArea}
					height={202}
					width={196}
					sizes="(max-width: 425px) 98px, (max-width: 640px) 134px, 186px"
				/>
			</div>
		</Card>
	);
};
