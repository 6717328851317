import { Logo } from '@krakentech/coral';
import { FC } from 'react';

import { AreaSelect } from '@/components/pages/home/Shortcut/AreaSelect';
import { UsageTierSelect } from '@/components/pages/home/Shortcut/UsageTierSelect';
import { SupportedArea } from '@/utils/constants/industry/gridOperator';
import { UsageTier } from '@/utils/constants/industry/industry';

export type ShortcutFormProps = {
	onSelectArea: (area: SupportedArea) => void;
	onSelectUsageTier: (usageTier: UsageTier) => void;
	selectedArea?: SupportedArea;
	selectedUsageTier?: UsageTier;
};

export const ShortcutForm: FC<ShortcutFormProps> = ({
	onSelectArea,
	onSelectUsageTier,
	selectedArea,
	selectedUsageTier,
}) => (
	<div className="flex flex-col gap-4 md:gap-6">
		<div className="flex flex-col items-center text-center">
			<div className="mb-5 text-xl font-semibold leading-normal text-voltage md:mb-6 md:text-2xl">
				グリーンな電気を、もっと安く
			</div>
			<div className="mb-2 w-full max-w-[600px]">
				<Logo />
			</div>
			<div className="flex flex-col text-sm leading-6 text-white md:flex-row md:text-lg">
				<span>2クリックで料金チェック</span>
				<span className="hidden md:block">・</span>
				<span>4分以内でカンタン申込</span>
			</div>
		</div>
		<div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6">
			<AreaSelect value={selectedArea} onSelect={onSelectArea} />
			<UsageTierSelect value={selectedUsageTier} onSelect={onSelectUsageTier} />
		</div>
	</div>
);
