import { Button, Card } from '@krakentech/coral';
import { FC } from 'react';

import { AreaPin } from '@/components/svgs/AreaPin';
import {
	SupportedArea,
	SUPPORTED_AREAS,
} from '@/utils/constants/industry/gridOperator';
import { sendHomepagePriceCheckAreaAnalytics } from '@/utils/googleAnalytics';

export const AreaSelect: FC<{
	onSelect: (area: SupportedArea) => void;
	value?: SupportedArea;
}> = ({ onSelect, value }) => {
	const handleClick = (area: SupportedArea) => {
		sendHomepagePriceCheckAreaAnalytics(area);
		onSelect(area);
	};

	return (
		<Card theme="dark" borderless padding="none">
			<div className="p-4 text-white md:p-8">
				<div className="mb-4 flex items-center justify-center gap-1 md:gap-4">
					<div className="w-8">
						<AreaPin />
					</div>
					<p className="text-lg md:text-2xl">
						お住まいのエリアを選んでください
					</p>
				</div>
				<div className="grid grid-cols-3 gap-4 md:gap-6 lg:gap-x-10">
					{SUPPORTED_AREAS.map((area) => (
						<Button
							variant={value === area ? 'contained' : 'outlined'}
							color="secondary"
							size="small"
							key={area}
							onClick={() => handleClick(area)}
						>
							<div className="py-1 text-sm font-semibold md:py-0 md:text-base">
								{area}
							</div>
						</Button>
					))}
				</div>
			</div>
		</Card>
	);
};
